import {
  CARESEEKER_TAB_TYPE_ARCHIVED,
  CARESEEKER_TAB_TYPE_DRAFT,
  CARESEEKER_TAB_TYPE_FINISHED,
  CARESEEKER_TAB_TYPE_PATIENTS,
  DOCUMENT_ADDENDUM,
  DOCUMENT_AV,
  DOCUMENT_DATA_PROCESSING_AGREEMENT,
  DOCUMENT_DISABILITY_STATEMENT_PROVIDER_SEARCH,
  DOCUMENT_EASY_LANGUAGE,
  DOCUMENT_IMPRINT,
  DOCUMENT_PRIVACY_POLICY,
  DOCUMENT_SIGN_LANGUAGE,
  DOCUMENT_TERMS_AND_CONDITIONS,
} from "core/consts";

function extensions() {
  return `
    extensions {
      active_since
      connected
      integration_id
      integration_type
    }`;
}

export function company() {
  return `
    id
    name
    force_id
    send_to_company_first
    status
    notes
    company_type
    parent_company  {
      id
      name
      status
      company_type
    }
    number_of_beds
    ik_numbers
    address {
      address
      street
      street_number
      zipcode
      city
      latitude
      country
      longitude
    }
    careseekers {
      id
      status
      name
    }
    careproviders {
      ${extensions()}
      id
      name
      patient_type
      specializations
      status
    }
    companies {
      id
      name
      status
    }
    onboarding_blob
  `;
}

function eventFilter() {
  return `
    type
    value
    values
  `;
}

const acceptedSolutions = () => {
  return `
  accepted_solutions {
    solution
    possible_entry_date
    arrival_time
  }`;
};

export function event(props?: { withEventLastSeens?: boolean }) {
  // 27.12. Miguel removed the id to avoid apollo normalization
  return `
    timestamp
    type
    user_id
    ${
      props?.withEventLastSeens
        ? `
    last_seens
    `
        : ""
    }
    seen_by_recipient
    seen_by_recipient_timestamp
    origin
    patient_id
    careseeker {
      id
      name
    }
    careprovider {
      id
      name
    }
    account {
      id
      first_name
      last_name
      gender
      account_type
      title
      academic_title
    }
    subject_account {
      id
      first_name
      last_name
      gender
      account_type
    }
    context {
      arrival_time
      bounced_mails {
        bounced_emails
        total_sent
        total_bounced
      }
      capacity_confirmed
      capacity_date
      careprovider_name
      clinic_internal_remarks
      declined_reason
      diff
      eligibility_reject_reason
      email
      file {
        ${file()}
      }
      file_type
      filters {
        ${eventFilter()}
      }
      insurance_name
      mail_type
      manual
      message
      message_iv
      new_assignee_name
      new_roles
      new_status
      no_capacity_expiration
      no_capacity_reasons
      old_assignee_name
      onboarding_comment
      onboarding_email_type
      patient_type
      possible_entry_date
      previous_status
      reason
      recipients
      reha_form_status
      request_link
      request_unavailable_reason
      seald_message
      solution_filter_reasons
      stop_description
      stop_reason
      user_id
     ${acceptedSolutions()}
     transferred_to_specialization
     winning_solution
     withdraw_reason
    }
  `;
}

export function notificationEvent() {
  return `
    id
    timestamp
    type
    user_id
    last_seens
    seen_by_recipient
    seen_by_recipient_timestamp
    origin
    patient_id
    auction {
      patient {
        id
        ${sessionKeyContext()}
        profile {
          ${encryptedField("first_name")}
          ${encryptedField("last_name")}
        }
      }
      solutions
      specializations
    }
    auction_request {
      id
      solution
      solutions
    }
    careseeker {
      id
      name
    }
    careprovider {
      specializations
    }
    account {
      id
      first_name
      last_name
      gender
      account_type
      title
      academic_title
    }
    context {
      careprovider_name
      patient_type
      user_id
    }
  `;
}

export function auctionResponse() {
  return `
    created_at
    updated_at
    possible_entry_date
    arrival_time
    capacity_date
    status
    declined_reason
    no_capacity_expiration
    no_capacity_reasons
    message
    message_iv
    seald_message
    internal_remarks
    ${acceptedSolutions()}
`;
}

export function auctionValidation() {
  return `
    updated_at
    status
    validation_reason
    auto_validated
    message
    message_iv
    seald_message
    internal_remarks
    solution
`;
}

export function file() {
  return `
    iv
    link
    name
    encrypted_name {
      content
      iv
    }
    title
    type
`;
}

export function careproviderDpa() {
  return `
    data_processing_agreement {
      file_name
      uploaded_at
      signed_at
      church_owned
    }
`;
}

export function careproviderFile() {
  return `
    id
    file_name
    file_type
    content
    url
`;
}

export function sessionKeyContext() {
  return `
    session_key_context {
      has_session_keys
      session_key {
        ${sessionKey()}
      }
    }
  `;
}

export const requestActions = `
request_actions {
  action_type
  context {
    disabled
  }
}
`;

export function auctionRequest(
  options: {
    withAccountRoles?: boolean;
    withPatient?: boolean;
    withRequestAccounts?: boolean;
  } = {
    withRequestAccounts: true,
    withAccountRoles: true,
    withPatient: true,
  },
): string {
  return `
    id
    status
    distance
    messages_count
    files_count
    action_required_by_user
    is_provider_search_request
    send_fax
    deleted_at
    created_at
    preferred
    patient_preferred
    updated_at
    fax_sent
    assignee_id
    assignee {
      id
      first_name
      last_name
    }
    token
    solutions
    ${requestActions}
    last_action {
      timestamp
      account {
        id
        first_name
        last_name
      }
    }
    seen_by_user
    response {
      ${auctionResponse()}
    }
    access_request_pending
    has_session_keys
    fax_sent
    messenger_available
    validation {
      ${auctionValidation()}
    }
    auction_id
    careprovider {
      ${careprovider()}
      thumbnail_url
      files {
        images
        documents
      }
    }
    careprovider_id
    manually_added
    last_event {
      ${event()}
    }
    last_seens {
      id
      timestamp
      account_id
      account {
        ${account({ withPublicKey: false })}
      }
      auction_request_id
    }
    auction {
      ${auction({ withPatient: options.withPatient, withRequests: false })}
    }
    old_auction {
      ${auction({ withPatient: true, withRequests: false })}
    }
    session_key {
      ${sessionKey()}
    }
    ${sessionKeyContext()}
    has_seald_encryption_context
    seald_encryption_context {
      ${encryptionContext()}
    }
    ${
      options.withRequestAccounts
        ? `accounts {
      account_type
      email
      first_name
      gender
      id
      last_name
      phone
      public_key
      ${
        options.withAccountRoles
          ? withRoles({
              withMinimalRoles: true,
              withProductFeatures: false,
              withOnlyCareproviderRoles: false,
            })
          : ""
      }
    }`
        : ""
    }`;
}

export function insurance() {
  return `
    id
    name
    type
    careseeker_id
    integration_platform
    is_active
    `;
}

export function sessionKey(options = { minimal: false }) {
  if (options.minimal)
    return `
      id
      session_key
      algorithm
    `;
  return `
    id
    updated_at
    account_id
    auction_request_id
    careprovider_id
    session_key
    patient_id
    algorithm
  `;
}

function sealdConfig() {
  return `seald_config {
    seald_flow
    seald_notes
  }`;
}

export const careproviderCapacity = `
    has_capacity
    available_date
    updated_at
  `;

export function careprovider(options = { withConfig: false }) {
  return `
    id
    new
    duplicated_from {
      id
      name
    }
    lead_id
    token
    inactive_reason
    patient_type
    receive_fax
    status
    seald_encryption_context {
      ${encryptionContext()}
    }
    activities {
      invite_contacts_popup
    }
    company {
      id
      name
      status
      ik_numbers
      company_type
    }
    onboarding {
      status
      comment
      call_back_date
      facility_size
      new_facility_openings
      responsible_person
      waiting_list_usage
      information_system
      information_system_other
    }
    no_capacities {
      id
      careprovider_id
      expiration
      no_capacity_reasons {
        type
        values
      }
    }
    onboarding_stats {
      active_since
      onboarding_source
    }
    name
    contacts {
      first_name
      last_name
      title
      position
      email
      landline_number
      mobile_number
      send_emails
      salutation
      academic_title
    }
    address {
      address
      street
      street_number
      zip_code
      city
      latitude
      country
      longitude
    }
    fax_number
    central_phone_number
    profile {
      opt_out_b2c
      caregiver_genders
      supported_health_insurance_types
      languages
      confessions
      room_types
      whitelisted_zipcodes
      cardiovascular_equipment
      digestive_endoscopy
      other_technical_equipment
      imagery
      radius_in_meter
      accepted_weight {
        min
        max
      }
      accepted_age {
        min
        max
      }
      additional_costs
      social_worker_on_site
      health_insurance_requirement
    }
    website
    ${careproviderDpa()}
    terms_and_conditions {
      date
      version
    }
    specializations
    services
    prerequisites
    facilities
    other_remarks
    feedback
    ${extensions()}
    central_email
    email_requests
    email_summary
    receive_sms
    ${
      options.withConfig
        ? `
      config {
        disable_seald
        ${sealdConfig()}
        seald_only_encryption
      }
    `
        : ""
    }
    trigger_ppm_self_onboarding
    ppm_receiver_id
    provider_search_info {
      eligible
      state
    }
    product_features {
        id
        feature
        expiration_date
        status
        start_date
      }
    capacity {${careproviderCapacity}}
    `;
}

function withRoles(options?: {
  withMinimalRoles: boolean;
  withOnlyCareproviderRoles: boolean;
  withProductFeatures: boolean;
}) {
  return `
    roles {
      ${roles(options)}
    }
  `;
}

export function socialWorker() {
  return `
    id
    title
    email
    status
    first_name
    account_type
    gender
    last_name
    phone
    fax_number
    position
    academic_title
    mobile_phone
  `;
}

export function getAccountWithinCareseekerGroup() {
  return `
    accounts {
      academic_title
      account_type
      created_at
      email
      fax_number
      first_name
      gender
      id
      last_name
      mobile_phone
      phone
      position
      sso_user_id
      status
      title
      ${withRoles()}
    }
    active_user_manager_accounts_count
    total_accounts
    total_filtered_accounts
  `;
}

export function getAccountWithinCareproviderGroup() {
  return `
    accounts {
      academic_title
      account_type
      created_at
      email
      fax_number
      first_name
      gender
      id
      last_name
      mobile_phone
      phone
      position
      sso_user_id
      status
      title
      ${withRoles({
        withMinimalRoles: false,
        withProductFeatures: false,
        withOnlyCareproviderRoles: true,
      })}
    }
    active_user_manager_accounts_count
    total_accounts
    total_filtered_accounts
  `;
}

export function getAccount(
  options: {
    withEncryptionStatus?: boolean;
    withEvents?: boolean;
    withMinimalRoles?: boolean;
    withProductFeatures?: boolean;
    withPublicKey?: boolean;
    withRoles?: boolean;
  } = {
    withEncryptionStatus: false,
    withRoles: false,
    withMinimalRoles: false,
    withPublicKey: false,
    withEvents: true,
    withProductFeatures: false,
  },
) {
  return `
  ${account(options)}
    experiments {
     account_id
     experiment_id
     experiment_name
     experiment_value
   }
    seald_two_man_rule_key
    seald_database_key
    seald_registered
    seald_test_identity
    sso_user_id
    ratings {
      score
      timestamp
      comment
    }
    activities {
      add_members_cta {
        updated_at
      }
    }
  `;
}

export function getSocialWorkersAccount() {
  return `
    id
    first_name
    last_name
    email
    salutation
    academic_title
    gender
    seald_user_license_token
    sso_user_id
  `;
}

export function account(
  options: {
    withEncryptionStatus?: boolean;
    withEvents?: boolean;
    withMinimalRoles?: boolean;
    withOnlyCareproviderRoles?: boolean;
    withProductFeatures?: boolean;
    withPublicKey?: boolean;
    withRoles?: boolean;
  } = {
    withEncryptionStatus: false,
    withProductFeatures: false,
    withRoles: false,
    withMinimalRoles: false,
    withPublicKey: false,
    withEvents: true,
  },
) {
  return `
    id
    seald_user_license_token
    title
    email
    email_requests
    email_summary
    receive_sms
    status
    first_name
    account_type
    first_login
    last_login
    gender
    last_name
    phone
    mobile_phone
    fax_number
    position
    academic_title
    intercom_id
    notes
    created_at
    browser_notifications_disabled
    ${options.withPublicKey ? "public_key" : ""}
    ${
      options.withRoles
        ? withRoles({
            withMinimalRoles: !!options.withMinimalRoles,
            withProductFeatures: !!options.withProductFeatures,
            withOnlyCareproviderRoles: !!options.withOnlyCareproviderRoles,
          })
        : ""
    }
    ${options.withEncryptionStatus ? "seald_encryption_status" : ""}
    ${
      options.withEvents
        ? `events {
            ${event()}
          }`
        : ""
    }
  `;
}

function hl7Diagnosis() {
  return `
  code
  description
  type
  `;
}

export function diagnosis() {
  return `
    main_diagnosis
    secondary_diagnosis
    hl7_diagnoses {
      ${hl7Diagnosis()}
    }
    hl7_secondary_diagnoses {
      ${hl7Diagnosis()}
    }
    additional_examinations_necessary
    medical_history
    multimorbidity
    chronic_pains
    dysphagia
    addictions
    allergies
    icd_code
    additional_icd_codes
    mental_health {
      orientation
      motivation
      communication
      dementia_description
      run_away_description
      protected_area_needed
      night_watch_description
      mental_issues
      depression
      anxiety
      aggressivity
      vigilance
      closed_station
      fixation
      dangerous
    }
    palliative {
      description
      palliative_certificate_needed
    }
    dependency_diagnosis {
      agitated
      agitated_description
      barthel_assessment
      dependency_level
      early_rehab_assessment
      has_help_at_home
      requires_support_with_feeding
      requires_walking_aid
      returning_home_short_term
      singer_assessment
    }
    infection_and_germs_state
    infection_and_germs {
      mrsa
      clostridien
      three_mrgn
      four_mrgn
      vre
      other
      remarks
      requires_isolation
    }
    physical_disabilities {
      mobility
      mobility_additional_information
      load_carrying_ability
      visual_impairment
      auditive_impairment
      orthese
      prothese
      assistive_equipment
      existing_medical_supplies
      prescribed_medical_supplies
      required_medical_supplies
    }
    hospital_stay {
      doctor_in_charge_in_hospital
      doctor_in_charge_in_hospital_phone
      operation_date
      operation_description
      geriatric_early_stage
    }
    limited_capacity {
      limited_cognitive_capacity
      limited_physical_capacity
      limited_psychological_capacity
      limited_social_behaviour
      other
    }
    rehabilitation_potential {
      evaluation
      description
    }
    rehabilitation_goals
    corona {
      test_result
      description
      vaccinated
    }
    corona_state
  `;
}

export function version() {
  return `
    id
    application
    version
  `;
}

export function encryptedField(name: string) {
  return `${name} {
      content
      decrypted
      iv
      seald_content
    }
  `;
}

export function profile(options = { minimal: false }) {
  return `
    ${encryptedField("first_name")}
    ${encryptedField("last_name")}
    ${encryptedField("birth_date")}
    age
    age_interval
    height_interval {
      min
    }
    weight_interval {
      min
      max
    }
    gender
    admission_date
    discharge_date
    care_duration_in_days
    interested_long_term_stay
    direct_transfer_necessary
    has_patient_consent
    ${
      !options.minimal
        ? `
      ${encryptedField("height")}
      ${encryptedField("weight")}
      living_situation
      living_situation_other
      lift_available_state
      lift_available_description
      barrier_free
      barrier_free_description
      unsafe_current_domestic_situation_state
      unsafe_current_domestic_situation
      preferences {
        specialist_doctor
        room_type
        accompanying_person
        barrier_free_room
      }
      communication {
        patient_cannot_communicate_in_german
        patient_language
        patient_uses_sign_language
        has_guardian
        guardian_description
        ${encryptedField("guardian_contact_information")}
        guardian_requested
        relatives_available
        ${encryptedField("relatives_description")}
        patient_is_contact
        ${encryptedField("patient_is_contact_description")}
      }
      financing {
        patient_has_health_insurance_state
        reimbursment_secured
        reimbursment_for_help_at_home
        preventative_care
        short_term_care
        relief_services
        private_payment_bool
        social_help_recipient_bool
        more_information
        insurance_id
        insurance {
          id
          name
        }
        ${encryptedField("insurance_number")}
        payout
        carelevel {
          level
          has_applied
          higher_level
          expedited_request
        }
        public_care_insurance_status
      }
      general_practitioner {
        name
        contact_details
      }
    `
        : ""
    }
  `;
}

export function transport() {
  return `
    transport_type
    transport_organiser
    require_accompanying_person
    ${encryptedField("accompanying_person")}
    daily_transport_needed
  `;
}

export function careNeeds() {
  return `
    wound_care {
      description
    }
    compression {
      description
    }
    static_care_disabled_patients {
      description
    }
    medication {
      description
      blood_thinner
    }
    psychiatric_care {
      description
    }
    injection {
      description
    }
    food {
      food_help
      meals
    }
    nutrition {
      parenteral_nutrition
      enteral_nutrition
    }
    incontinence {
      incontinence_help_type
      requires_material_supplies
      urinary_incontinence
      fecal_incontinence
    }
    breathing {
      tracheostomy
      specialised_intensive_nurse
      oxygen_therapy
    }
    dialysis {
      hemodialysis
      peritoneal_dialysis
    }
    important_information
    base_care {
      description
      companionship_grant
    }
    basic_medical_care {
      description
    }
    companionship {
      description
      companionship_grant
    }
    palliative {
      description
      palliative_certificate_needed
    }
    help_at_home {
      description
    }
    remarks
    support {
      eating
      body_care
      dressing
    }
    monitoring {
      circulation
      respiration
      metabolism
    }
    access_drainages {
      tracheal_cannula
      gastric_tube
      central_venous_catheter
      peripheral_venous_catheter
      suprapubic_catheter
      permanent_catheter
      drainage
      intravenous_injection
      other_access_drainages
    }
  `;
}

export function careseekerModule({
  withAdminFields,
}: {
  withAdminFields: boolean | null | undefined;
}) {
  return withAdminFields
    ? `{
    status
    onboarding_email_link
    start_date
    onboarding_requirements
    onboarding_radius_km
    prio_list_activation_target
  }`
    : `{
    status
  }`;
}

export function careseeker({
  withAdminFields,
  withSSO,
}: {
  withAdminFields: boolean | null | undefined;
  withSSO?: boolean;
}) {
  return `
    id
    intercom_id
    name
    external_id
    kim_address
    status
    seald_encryption_context {
      ${encryptionContext()}
    }
    central_email
    created_at
    updated_at
    contact_name
    contact_phone
    ${withSSO ? "sso_integration" : ""}
    company_id
    company {
      id
      force_id
      name
      status
    }
    modules {
      care ${careseekerModule({ withAdminFields })}
      reha ${careseekerModule({ withAdminFields })}
      hospital ${careseekerModule({ withAdminFields })}
      transport ${careseekerModule({ withAdminFields })}
      medical_supplies ${careseekerModule({ withAdminFields })}
    }
    on_premise_config_id
    activate_on_premise
    number_of_beds
    ppm_sender_id
    stations
    notes
    address {
      zipcode
      address
      city
      latitude
      country
      longitude
      street
      street_number
    }

    ${
      withAdminFields
        ? `sender_user_management_file {
      ${userManagementFile}
    }`
        : ""
    }
    ${
      withAdminFields
        ? `priority_providers {
      id
      name
      status
      patient_type
      onboarding {
        status
      }
    }`
        : ""
    }
    patient_types
    banking_details {
      iban
      bank_name
      ik_number
    }
      product_features {
        id
        feature
        expiration_date
        status
        start_date
      }
  `;
}

export const userManagementFile = `
  id
  careseeker_id
  account_id
  file_name
  created_at`;

export const getLocation = ({
  withFederalState,
}: {
  withFederalState?: boolean;
} = {}) => {
  return `
  zipcode
  latitude
  floor
  longitude
  radius_in_meter
  ${encryptedField("encrypted_house_number")}
  street
  city
  ${withFederalState ? "federal_state" : ""}
`;
};

export function auction(
  options: {
    withAccountRoles?: boolean;
    withPatient?: boolean;
    withRequestAccounts?: boolean;
    withRequests?: boolean;
  } = {
    withPatient: false,
    withRequests: false,
    withRequestAccounts: false,
    withAccountRoles: false,
  },
): string {
  return `
    id
    created_at
    search_type
    patient_id
    assessment_completed
    last_status_change
    all_contacted
    start_date
    start_date_flexible
    assessment_variant
    pending_requests
    all_negative
    radius_in_meter
    country_wide_search
    status
    candidates_status
    action_required_by_user
    share_patient_data_automatically
    solutions
    medical_supplies {
      prescription_issued
      prescription_issued_description
      delivery_address_type
      additional_information
      delivery_address {
        ${getLocation()}
      }
      products
    }
    specializations
    additional_specializations
    services
    seen_by_user
    request_count
    cancel_validation_reason
    ${sessionKeyContext()}
    seald_encryption_context {
      ${encryptionContext()}
    }
    profile {
      search_location {
        ${getLocation({ withFederalState: true })}
      }
      search_destination {
        ${getLocation()}
      }
      pick_up {
        start {
          hour
          minute
        }
        end {
          hour
          minute
        }
      }
      no_site_support_required
      is_elective
      needs_oxygen
      is_visually_impaired
      monitoring_needs {
        ecg
        pulse_oximeter
      }
      has_transitional_care
      patient_type_updated
    }
    payers {
      selected_payment_method
      insurance
      civil_servants_aid
      public_accident_insurance
      public_pension
      social_service_department
      self_payer
      other_payer
      further_information
      supplementary_insurance
      supplementary_insurance_state
      supplementary_payment
      supplementary_payment_state
      free_from_supplementary_payment_state
    }
    winner {
      careprovider {
        ${careprovider()}
      }
      careprovider_name
      unknown_provider
      external_provider
      updated_at
      created_at
      auction_request_id
      solution
      specialization
    }
    cancel_auction {
      reason
      description
    }
    ${
      options.withPatient
        ? `patient {
          ${patient({
            withAuctions: false,
            withRequests: false,
          })}
        }`
        : ""
    }
    ${
      options.withRequests
        ? withRequests({
            withRequestAccounts: options.withRequestAccounts,
            withAccountRoles: options.withAccountRoles,
          })
        : ""
    }
    max_accepts
    ${searchActions}
    is_stale
`;
}

export const searchActions = `
search_actions {
  action_type
  context {
    disabled
    variant
  }
}`;

export function roles(options?: {
  withMinimalRoles: boolean;
  withOnlyCareproviderRoles: boolean;
  withProductFeatures: boolean;
}) {
  if (options?.withOnlyCareproviderRoles) {
    return `
    careprovider_roles {
      careprovider {
        id
      }
      roles
    }`;
  }

  return `
    careprovider_roles {
      careprovider {
        id
        ${
          options?.withProductFeatures
            ? ` product_features {
                id
                feature
                expiration_date
                status
                start_date
              }`
            : ``
        }
        ${
          options?.withMinimalRoles
            ? ``
            : `
        name
        status
        address {
          country
        }`
        }
      }
      roles
    }
    careseeker_roles {
      careseeker {
        id
          ${
          options?.withProductFeatures
            ? ` product_features {
                id
                feature
                expiration_date
                status
                start_date
              }`
            : ``
        }
        ${
          options?.withMinimalRoles
            ? ``
            : `
        name
        type
        address {
          country
        }`
        }
      }
      roles
    }
    admin_roles
`;
}

export const lightRequests = `
requests_light {
  id
  status
  seen_by_user
}`;

function withRequests(
  {
    withAccountRoles,
    withRequestAccounts,
  }: {
    withAccountRoles?: boolean;
    withRequestAccounts?: boolean;
  } = { withRequestAccounts: false, withAccountRoles: false },
) {
  return `
    ids
    total
    requests {
      ${auctionRequest({ withRequestAccounts, withAccountRoles })}
      session_key {
        ${sessionKey()}
      }
    }
  `;
}

function rehabForm() {
  return `
    medical_form_input {
      iv
      type
      content
      seald_content
      updated_at
    }
    request_form_input {
      iv
      type
      content
      seald_content
      updated_at
    }
  `;
}

export function rejectedProvider() {
  return `
    careprovider {
      ${careprovider()}
    }
    solution
  `;
}

export function careseekerConfig() {
  return `
    ${sealdConfig()}
    activate_dta
    activate_gateway_debug_mode
    activate_parallel_search
    activate_search_merge
    activate_predict
    activate_ssl_hl7_connector
    auto_validate
    blacklisted_assessment_fields
    deactivate_received_files
    disable_seald
    filter_on_social_worker
    ip_range
    jwt_expiration_in_minutes
    on_premise_archive_integration_enabled
    on_premise_authorization_token
    on_premise_domain_ssl
    on_premise_file_sync
    seald_only_encryption
    use_kis_integration
    whitelisted_email_domains
    whitelisted_home_care_specializations
    whitelisted_hospital_specializations
    whitelisted_reha_specializations
  `;
}

// we need this while the feature flags are mixed up with
// other careseeker config that shouldn't be sent everywhwere
// (like whitelisted domains to the receivers)
function customPatientCareseekerConfig() {
  return `
  activate_search_merge
  deactivate_received_files
  `;
}

export function patient(
  options: {
    minimal?: boolean;
    withAuctions?: boolean;
    withRequests?: boolean;
  } = {
    withAuctions: true,
    withRequests: false,
    minimal: false,
  },
) {
  return `
    id
    version
    drg_code
    external_id
    user_id
    created_at
    station
    station_full {
      ${station()}
    }
    station_full_id
    kis_import
    rehab_form {
      ${rehabForm()}
    }
    social_worker {
      ${socialWorker()}
    }
    assignee {
      ${socialWorker()}
    }
    seald_encryption_context {
      ${encryptionContext()}
    }
    ${sessionKeyContext()}
    session_keys {
      ${sessionKey()}
    }
    careseeker {
      ${careseeker({ withAdminFields: false })}
      type
      config {
        ${customPatientCareseekerConfig()}
      }
    }
    transport {
      ${transport()}
    }
    diagnosis {
      ${diagnosis()}
    }
    profile {
      ${profile({ minimal: options.minimal || false })}
    }
    ${
      options.withAuctions
        ? `auctions {
          ${auction()}
        }`
        : ""
    }
    care_needs {
      ${careNeeds()}
    }
    archived
  `;
}

function getCareproviderRequestsAuction() {
  return `
    id
    status
    search_type
    ${sessionKeyContext()}
    seald_encryption_context {
      ${encryptionContext()}
    }
    profile {
      search_location {
        ${getLocation()}
      }
      pick_up {
        start {
          hour
          minute
        }
        end {
          hour
          minute
        }
      }
    }
    start_date
    start_date_flexible
    specializations
    patient {
      id
      patient_type
      user_id
      ${sessionKeyContext()}
      seald_encryption_context {
        ${encryptionContext()}
      }
      social_worker {
        ${socialWorker()}
      }
      diagnosis {
        ${diagnosis()}
      }
      profile {
        ${encryptedField("first_name")}
        ${encryptedField("last_name")}
        ${encryptedField("birth_date")}
        gender
        age_interval
        financing  {
          carelevel {
            level
          }
        }
      }
      payers {
        selected_payment_method
        insurance
        civil_servants_aid
        public_accident_insurance
        public_pension
        social_service_department
        self_payer
        other_payer
        further_information
        supplementary_insurance
        supplementary_insurance_state
        supplementary_payment
        supplementary_payment_state
        free_from_supplementary_payment_state
      }
      careseeker {
        id
        name
        type
        address {
          country
        }
      }
    }
  `;
}

export function getCareproviderRequests() {
  return `
    id
    status
    solutions
    manually_added
    preferred
    patient_preferred
    messenger_available
    messages_count
    files_count
    action_required_by_user
    seen_by_user
    token
    is_provider_search_request
    is_reverse_search_match
    created_at
    assignee_id
    assignee {
      id
      first_name
      last_name
    }
    response {
      status
      arrival_time
      ${acceptedSolutions()}
    }
    last_action {
      timestamp
      account {
        id
        first_name
        last_name
      }
    }
    validation {
      updated_at
      status
      validation_reason
      solution
    }
    has_capacity
    auction {
      ${getCareproviderRequestsAuction()}
    }
    ${sessionKeyContext()}
    session_key {
      ${sessionKey()}
    }
    seald_encryption_context {
      ${encryptionContext()}
    }
    careprovider_id
    auction_id
  `;
}

export function getCareprovider(options?: { withRoles?: boolean }) {
  return `
    ${careprovider({ withConfig: true })}
    opportunity_id
    assignee {
      id
      first_name
      last_name
    }
    pending_messages
    metrics {
      avg_time_to_open
      avg_time_to_click
      avg_time_to_response

      request_count
      response_count
      accept_count
      maybe_count
      attributed_count

      local_request_count
      local_response_count
      local_accept_count
      local_maybe_count
      local_attributed_count
    }
    events {
      ${event()}
    }
    accounts {
      ${account({
        withPublicKey: true,
        withEvents: true,
        withRoles: options?.withRoles,
        withMinimalRoles: options?.withRoles,
      })}
    }
    related_careseekers {
      careseeker {
        id
        name
        status
        start_date
      }
      distance
      prioritized
    }
  `;
}

export function getCareproviderListingDetails() {
  return `
    id
    name
    address {
      address
      zip_code
      city
    }
    distance
    central_phone_number
    fax_number
    central_email
    website
    capacity {
        updated_at
        capacity_status
      }
    services
    facilities
    files
    `;
}

export function station() {
  return `
    id
    name
    description
    phone_number
  `;
}
// providers,contacts,events,information,config
export function getCareseeker({
  withAdminFields,
  withConfig,
  withSSO,
}: {
  withAdminFields: boolean | null | undefined;
  withConfig: boolean | null | undefined;
  withSSO?: boolean;
}) {
  return `
    ${careseeker({ withAdminFields, withSSO })}
    type
    ${
      withAdminFields || withConfig
        ? `config {
      ${careseekerConfig()}
    }`
        : ``
    }
    stations_full {
      ${station()}
    }
    banking_details {
      bank_name
      iban
      ik_number
    }
  `;
}

export function getAddress() {
  return `
    radius
    street
    zipcode
    city
    floor
    coordinates {
      latitude
      longitude
      __typename: CoordinatesPayload
    }
  `;
}

export function feedback() {
  return `
    id
    account_id
    careprovider_id
    careseeker_id
    patient_id
    created_at
    feedback
    `;
}

export function translation() {
  return `
    id
    created_at
    updated_at
    locales {
      en
      de
    }
    tags
    description
    links {
      description
      link
    }
  `;
}

export const emailTranslation = `
    id
    created_at
    updated_at
    translation
  `;

export function pendingSessionKey() {
  return `
    account_public_key
    created_at
    account_id
    auction_request_id
    patient_id
    careseeker_id
    careprovider_id
    user_session_key
    algorithm
  `;
}

const countProperties = `
  count
  new
  hash
`;

export function requestCounts() {
  return `
    attributed {
      ${countProperties}
    }
    new {
      ${countProperties}
    }
    direct {
      ${countProperties}
    }
    in_progress {
      ${countProperties}
    }
  `;
}

export function patientCounts() {
  return `
    ${CARESEEKER_TAB_TYPE_DRAFT} {
      ${countProperties}
    }
    ${CARESEEKER_TAB_TYPE_PATIENTS} {
      ${countProperties}
    }
    ${CARESEEKER_TAB_TYPE_FINISHED} {
      ${countProperties}
    }
    ${CARESEEKER_TAB_TYPE_ARCHIVED} {
      ${countProperties}
    }
  `;
}

export function onPremiseConfig() {
  return `
    id
    name
    config
    hl7_version
    on_premise_domain
    on_premise_authorization_token
    on_premise_domain_ssl
    activate_gateway_debug_mode
    activate_ssl_hl7_connector
    on_premise_file_sync
    on_premise_archive_integration_enabled
    careseekers {
      id
      activate_on_premise
      external_id
      name
    }
    token
    should_restart
    predict_action {
      type
      context
    }
  `;
}

export function onPremiseConfigImport() {
  return `
    admit_date_time
    allergies
    anamnesis
    attending_doctor
    barthel_assessment
    birth_date
    care_level
    careseeker_id
    consulting_doctor
    created_at
    discharge_date_time
    early_rehab_assessment
    evolution
    external_id
    first_name
    gender
    guardians
    height
    id
    import_status
    infections
    insurance_name
    insurance_number
    last_name
    main_diagnoses
    medical_history
    medical_instructions
    patient_address
    patient_city
    patient_postal_code
    recommended_routine
    relatives
    secondary_diagnoses
    singer_assessment
    station
    weight
  `;
}

export function formConfigurationScope() {
  return `
    country
    specializations
    patient_type
    solutions
    sender_type
    variant
  `;
}

export function formConfiguration() {
  return `
    form_name
    param_name
    position
    scopes {
      ${formConfigurationScope()}
    }
  `;
}

export function senderReceiverNote() {
  return `
    id
    note
  `;
}

export function SenderReceiverRules() {
  return `
    id
    created_at
    careseeker_id
    careprovider_id
    filter_type
    specialization
    product_group
    search_partial_zipcode
    is_whitelist
  `;
}

export function encryptionJob() {
  return `
    auction_id
    fhir_patient
    id
    patient_encrypted_session_key {
      ${sessionKey({ minimal: true })}
    }
    patient_seald_encryption_context {
      ${encryptionContext()}
    }
    receiver_integration_public_key
  `;
}

export function encryptionContext() {
  return `
    id
    seald_id
    seald_display_id
    seald_type
    careseeker_id
    careprovider_id
    patient_id
    auction_request_id
    file_id
  `;
}

export function sealdIdentityPayload() {
  return `
    seald_user_license_token
    seald_two_man_rule_key
    seald_two_man_rule_session_id
    must_authenticate
  `;
}

export function reverseSearches() {
  return `
    careprovider_id
    enabled
    filter {
      solutions
      genders
      age_interval {
        min
        max
      }
      start_date_range {
        to_date
        from_date
      }
      care_levels
    }
    id
    `;
}

export function filev2() {
  return `
  account {
    id
    first_name
    last_name
  }
  auction_id
  careprovider {
    id
    name
  }
  careseeker {
    id
    name
  }
  category
  category_other
  created_at
  file_name {
    seald_content
  }
  file_type
  id
  kis_document_id_string
  link
  seald_encryption_context {
    ${encryptionContext()}
  }
  share_mode
  shared_by {
    id
  }
  shared_with {
    ${filev2SharedWith()}
  }
  updated_at
  `;
}

export function filev2SharedWith() {
  return `
    careseeker_ids
    selected_careproviders {
      account_id
      careprovider {
        id
        name
      }
    }
  `;
}

export function filev2SelectableCareproviders() {
  return `
    id
    name
  `;
}

function groupAccess() {
  return `
    id
    name
    pending_access_created_at
    seald_access
    seald_db_access_created_at
  `;
}

export function sessionAccesses() {
  return `
    careprovider_accesses {
      ${groupAccess()}
    }
    careseeker_accesses {
      ${groupAccess()}
    }
    seald_encryption_context_created_at
  `;
}

const openingHours = `
  opening_hours {
    monday
    tuesday
    wednesday
    thursday
    friday
    saturday
    sunday
  }
`;

export const providerSearchProvider = `
    id
    name
    address {
      address
      zip_code
      city
      latitude
      country
      longitude
    }
    capacity {
      updated_at
      available_date
      has_capacity
      capacity_status
    }
    distance
    services
    facilities
    profile {
      accepted_age {
        min
        max
      }
      accepted_weight {
        min
        max
      }
    }
    website
    central_phone_number
    central_email
    cover_image_file {
      file_name
      url
    }
    is_consultant
    other_remarks
    project_sponsor
    ${openingHours}
  `;

export const providerListing = `
  total
  remaining
  cards {
      id
      name
      address {
        address
        zip_code
        city
        latitude
        longitude
      }
      facilities
      capacity {
        updated_at
        capacity_status
        }
      distance
      thumbnail_url
      is_consultant
      project_sponsor
      website
      central_phone_number
      central_email
    }
`;

export const providerListingB2C = `
  total
  remaining
  careproviders {
      id
      name
      address {
        address
        zip_code
        city
        latitude
        longitude
      }
      facilities
      capacity {
        updated_at
        capacity_status
      }
      distance
      thumbnail_url
      is_consultant
    }
`;

export const manuallyAddedProvider = `
    id
    name
    address {
      address
      zip_code
      city
      latitude
      country
      longitude
    }
`;

export const providerSearchPatient = (active: boolean) => `
        id
        profile_id
        user_id
        profile_id
        care_needs {
          base_care {
            description
          }
          basic_medical_care {
            description
          }
          psychiatric_care {
            description
          }
          breathing {
            specialised_intensive_nurse
          }
          static_care_disabled_patients {
            description
          }
          palliative {
            description
            palliative_certificate_needed
          }
        }
        diagnosis {
          mental_health {
            dementia_description
          }
        }
        profile {
          weight_interval {
            min
            max
          }
          age_interval {
            min
            max
          }
          financing {
            carelevel {
              level
            }
          }
          gender
          care_duration_in_days
        }
        ${
          active
            ? `active_requests {
          id
          careprovider_id
        }`
            : ""
        }
        seald_encryption_context {
          ${encryptionContext()}
        }
        archived_at
        archived
`;

const providerSearchOverviewRequest = () => `
  action_required_by_user
  auction {
    created_at
    id
    last_status_change
    start_date
    }
  careprovider {
    id
    address {
      city
      }
    name
    }
  created_at
  id
  response {
    updated_at
    }
  solutions
  status
  validation {
    updated_at
   }
`;

export const providerSearchRequestOverview = `
  validated {
    requests {
      ${providerSearchOverviewRequest()}
    }
  }
  active {
    requests {
      ${providerSearchOverviewRequest()}
    }
  }
  inactive {
    requests {
      ${providerSearchOverviewRequest()}
    }
  }
  `;

export const acpConsultant = `
id
name
address {
  address
  zip_code
  city
  latitude
  country
  longitude
}
website
central_phone_number
central_email
description
project_sponsor
status
${openingHours}
`;

const legalDocumentProperties = `
    needs_to_sign
    signed_at
    url
    version
`;
export const legalDocuments = `
    legal_document_map {
      ${DOCUMENT_DATA_PROCESSING_AGREEMENT} {
        ${legalDocumentProperties}
      }
      ${DOCUMENT_PRIVACY_POLICY} {
        ${legalDocumentProperties}
      }
      ${DOCUMENT_TERMS_AND_CONDITIONS} {
        ${legalDocumentProperties}
      }
      ${DOCUMENT_AV} {
        ${legalDocumentProperties}
      }
      ${DOCUMENT_ADDENDUM} {
        ${legalDocumentProperties}
      }
      ${DOCUMENT_DISABILITY_STATEMENT_PROVIDER_SEARCH} {
        ${legalDocumentProperties}
      }
      ${DOCUMENT_IMPRINT} {
        ${legalDocumentProperties}
      }
      ${DOCUMENT_EASY_LANGUAGE} {
        ${legalDocumentProperties}
      }
      ${DOCUMENT_SIGN_LANGUAGE} {
        ${legalDocumentProperties}
      }
      __typename
  }
`;

export const transitionalCareFragment = `
  transitional_care {
    id
    ${encryptedField("form_data")}
    updated_at
    created_at
  }
`;

export const transitionalCare = `
    id
    ${transitionalCareFragment}
    ${sessionKeyContext()}
    seald_encryption_context {
      ${encryptionContext()}
    }
`;

export const rehabFormFragment = `
    id
    ${encryptedField("form_data")}
    updated_at
    created_at
    rehab_form_subtype
    was_migrated
`;

export const rehabFormsFragment = `
  rehab_forms {
      general_form {${rehabFormFragment}}
      medical_form {${rehabFormFragment}}
  }
`;

export const rehabForms = `
    id
    ${rehabFormsFragment}
    ${sessionKeyContext()}
    seald_encryption_context {
      ${encryptionContext()}
    }
`;

export const groupSessionsResponse = `
    pending_seald_access_count
    lost_sessions {
      active_files
      active_patients
      active_requests
      archived
    }
  `;

export const prompt = `
    id
    name
    prompt
`;

export const schema = `
    id
    name
    json_schema
    form_type
`;

export const bedrockExample = `
      id
      name
      example_response
      example_transcript
      form_type
`;

export const bedrockAudioRecording = `
      id
      name
      form_type
      transcript
      link
`;

export const bedrockResponse = `
      id
      audio_recording_id
      audio_recording {
        ${bedrockAudioRecording}
      }
      example_id
      example {
        ${bedrockExample}
      }
      prompt_id
      prompt {
        ${prompt}
      }
      schema_id
      schema {
        ${schema}
      }
      response
      form_type
      model_config {
        model_id
        temperature
        max_tokens
        top_k
        top_p
      }
`;

export const bedrockPlayground = `
    audio_recordings {
      ${bedrockAudioRecording}
    }
    prompts {
      ${prompt}
    }
    schemas {
      ${schema}
    }
    examples {
      ${bedrockExample}
    }
    responses {
      ${bedrockResponse}
    }
`;
